import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import DefaultBlocks from '@fragment/shared/DefaultBlocks';
import CompleteFloatingImageBlock from '@fragment/shared/CompleteFloatingImageBlock';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteColumnTierColumn on TwoColumnTierColumn {
        blockType
        ... on TwoColumnTierColumn {
            backgroundImage {
                ...CompleteCustomImage
            }
            blockStyle
            verticalAlignment
            highlight
            blocks {
                ...DefaultBlocks
                ...CompleteFloatingImageBlock
            }
        }
    }
    ${CompleteCustomImage}
    ${DefaultBlocks}
    ${CompleteFloatingImageBlock}
`;
